import siteMixin from "@/mixins/siteMixin";
import helperMixin from "@/mixins/helperMixin";
import userMixin from "@/mixins/userMixin";
import permissionsMixin from "@/mixins/permissionsMixin";
export default {
    data: () => ({
        token: "1hbnPuxxKguJe7HlgIHhIBeWvoVGgJrh",
        mc2ApiRoute: process.env.VUE_APP_MC2_API + "dashboard/clients/",
        headers: {},
        methodPermissions: {
            getGlobalInfo: "view_dashboard_account_settings",
            getSubscriptionInfo: "view_dashboard_account_settings",
            getClientInfo: "view_dashboard_account_settings",
            getEmails: "view_dashboard_account_settings",
            getIsHeyGov: "view_dashboard_account_settings",
            getSiteGoogleMetrics: "view_dashboard_account_settings",
            getNotifications: "view_dashboard_notifications",
            getUnreadedNotificationsCount: "view_dashboard_notifications",
            getInvoices: "view_dashboard_account_settings",
            // 'checkOverdue': 'view_dashboard_account_settings'
        },
        controller: null,
    }),
    methods: {
        async getGlobalInfo() {
            let currentUser = this.getCurrentUser();
            if (!currentUser) {
                return;
            }

            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "global" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getSubscriptionInfo() {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "subscriptionInfo" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getClientInfo() {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "clientInfoGet" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async updateClientInfo(client) {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "clientInfoUpdate", client: client }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getEmails() {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "emails" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getIsHeyGov() {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "hgClient" }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getSiteGoogleMetrics(dateRange) {
            // GA4-307826617

            // this.site = this.removeHttp(this.getSite().url)

            // let data = new FormData();

            // data.append('method', 'ga');
            // data.append('token', 'this.token');
            // data.append('start_date', dateRange);

            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, start_date: dateRange, action: "ga" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async getNotifications(onlyUnreaded = false) {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "notifications", new: onlyUnreaded }, { headers: this.headers });

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async setNotificationsViewed(ids) {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "notificationsViewed", ids: ids });

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async setNotificationsUnread() {
            await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "resetNotifications" });
        },
        async getUnreadedNotificationsCount() {
            // let currentUser = this.getCurrentUser();
            // if (!currentUser) {
            //     return;
            // }
            // let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "notificationsCount" }, { headers: this.headers });
            // if (res.status && res.status === 200) {
            //     return res.data;
            // }
        },
        async getInvoices() {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "invoices" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async invoiceAskFor(emails, invoiceId) {
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "sendInvoices", emails: emails, invoiceId: invoiceId }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async checkOverdue() {
            let currentUser = this.getCurrentUser();
            if (!currentUser) {
                return;
            }
            let res = await this.axios.post(this.mc2ApiRoute + this.site, { token: this.token, action: "overdue" }, { headers: this.headers }); // action additional param

            if (res.status && res.status === 200) {
                return res.data;
            }
            // await this.sleep(1000)
            // return {overdue: true, override: true, message: "<div>Your account is overdue ($415), please make a payment as soon as possible.</div>"}
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
    },
    computed: {
        site() {
            let site = this.removeHttp(this.getSite().url);
            if (site.includes("www.")) {
                site = site.replace("www.", "");
            }

            if (site.startsWith("localhost") || site.startsWith("127.0.0.1") || site.includes(".townwebinc.")) {
                // site = 'milanmo.gov';
                // site = 'laprairiewi.gov';
                site = "cityofolean.org";
                // site = '2022theme.townwebinc.com';
            }
            return site;
        },
    },
    mixins: [siteMixin, helperMixin, userMixin, permissionsMixin],
};
