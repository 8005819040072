<!-- Modal -->
<template>
    <!-- <button type="button" class="btn btn-primary" @click="modal.show()">
        Launch demo modal
    </button> -->
    <div class="modal fade" id="overdueModal" ref="overdueModal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content tw-red-bg-color text-white">
                <div class="modal-header">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="modal-title p-1" id="modal-title">YOUR ADMIN CAPABILITIES HAVE BEEN DISABLED</h3>
                        </div>
                    </div>
                </div>
                <div class="modal-body" id="modal-body" v-html="messageHtml"></div>
                <div class="modal-footer">
                    <div>
                        <div class="mb-1">For billing contact us at <a class="text-white fw-bold text-decoration-none" href="mailto:billing@townweb.com">billing@townweb.com</a></div>
                        <div>For support contact us at <a class="text-white fw-bold text-decoration-none" href="mailto:support@townweb.com">support@townweb.com</a></div>
                    </div>
                    <button v-if="userCanClose" class="btn tw-dark-grey-bg-color text-white mt-2" type="button" @click="modal.hide()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap";
import userMixin from "@/mixins/userMixin";
import accountSettingsMixin from "@/mixins/accountSettingsMixin";
export default {
    name: "OverdueModal",
    data: () => ({
        modal: null,
        messageHtml: "",
        // page: {},
        // title: '',
        // content: '',
        // url: '',
        // auth: null,
        overdue: false,
        // sendError: false,
    }),
    mounted() {
        this.modal = new Modal(this.$refs.overdueModal);

        this.checkForOverdue();
    },
    methods: {
        async checkForOverdue() {
            // get overdue data from mc2
            // let response = await this.checkOverdue();
            // if(response && response.overdue && !response.override) {
            //     this.messageHtml = response.message;
            //     this.modal.show();
            // }
            // console.log(response)
        },
    },
    computed: {
        // siteType() {
        //     return this.getSiteType();
        // },
        userCanClose() {
            return this.userCanCloseOverdueWarning();
        },
    },
    mixins: [userMixin, accountSettingsMixin],
};
</script>
<style scoped>
#overdueModal {
    backdrop-filter: blur(5px);
    opacity: 1 !important;
}
.modal .modal-dialog .modal-content {
    border-radius: 0;
}
.modal .modal-dialog .modal-content .modal-header {
    border-radius: 0;
    border-bottom: 3px solid #000;
    justify-content: center;
    height: auto;
}
.modal .modal-dialog .modal-content .modal-body {
    padding: 30px;
    padding-bottom: 30px;
    justify-content: center;
    text-align: center;
}
.modal .modal-dialog .modal-content .modal-footer {
    border-top: 3px solid #000;
    justify-content: space-between;
}
</style>
