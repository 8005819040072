<template>
    <ul id="tw-main-nav" class="nav nav-tabs nav-fill">
        <li class="nav-item">
            <router-link class="nav-link" to="/" exact>Home</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_events', wp: 'events', tooltip: true }">
            <router-link class="nav-link" to="/events" exact-path>Events</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_pages', wp: 'pages', tooltip: true, model: 'pages' }">
            <router-link class="nav-link" to="/pages" exact-path>Pages</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_meeting_repository', wp: 'agendas', tooltip: true }">
            <router-link class="nav-link" to="/meeting-repository" exact-path><span class="pe-1" v-if="subscribedToMinutes && siteType === 'cms6'">✨</span>Meeting Repository</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_news_notices', wp: 'notices', tooltip: true, model: 'news_notices' }">
            <router-link class="nav-link" to="/news-notices">News/Notices</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_departments', supports: supportsDepartments, tooltip: true, model: 'departments' }">
            <router-link class="nav-link" to="/departments">Departments</router-link>
        </li>
        <li class="nav-item" v-if="siteType == 'cms6'" v-permission="{ juniper: 'view_faqs', tooltip: true, model: 'faqs' }">
            <router-link class="nav-link" to="/faqs">Faqs</router-link>
        </li>
        <li class="nav-item" v-permission="{ juniper: 'view_media_library', tooltip: true }">
            <router-link class="nav-link" to="/media-library" exact-path>Media Library</router-link>
        </li>
    </ul>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import siteMixin from "@/mixins/siteMixin";
// import { Tooltip } from "bootstrap";
import { permission } from "@/directives/permission";
import { mapState } from "vuex";
export default {
    name: "Nav",
    data: () => ({
        permissions: null,
        supportsDepartments: false,
        tooltip: false,
    }),
    directives: {
        permission,
    },
    mounted() {
        this.permissions = this.getCurrentUserPermissions();
        this.supportsDepartments = this.doesSupportDepartments();
    },
    updated() {
        let currentUser = this.getCurrentUser();
        if (!currentUser) {
            return;
        }
    },
    computed: {
        siteType() {
            return this.getSiteType();
        },
        ...mapState(["subscribedToMinutes"]),
    },
    mixins: [userMixin, siteMixin],
};
</script>
