<template>
    <Overlay v-if="currentRouteName != 'ResetPassword'" />
    <LoginModal v-if="currentRouteName && currentRouteName != 'ResetPassword'" />
    <Header v-if="currentRouteName && currentRouteName != 'ResetPassword'" />
    <div class="container-fluid">
        <div class="row">
            <router-view name="c" />
            <router-view name="a" />
            <!-- <router-view v-slot="{ Component }">
                <transition>
                    <component :is="Component" />
                </transition>
            </router-view> -->
        </div>
    </div>
    <!-- <FreeScoutChatbot /> -->
</template>

<script>
import Header from "@/components/Header";
// import FreeScoutChatbot from '@/components/FreeScoutChatbot'
import LoginModal from "@/components/modals/LoginModal";
import Overlay from "@/components/overlay/Overlay";
export default {
    name: "App",
    components: {
        Header,
        LoginModal,
        Overlay,
        // FreeScoutChatbot,
    },
    computed: {
        currentRouteName() {
            return this.$router.currentRoute.value.name;
        },
    },
};
</script>
