import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "bootstrap";
import moment from "moment-timezone";
import VueSweetalert2 from "vue-sweetalert2";
import { PDFPlugin } from "vue3-pdfmake";

// directives
import { permission } from "@/directives/permission";

import setupInterceptors from "./services/setupInterceptors";

import Datepicker from "vue3-date-time-picker";

import "vue3-date-time-picker/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/multiselect/themes/default.css";

// tracing
import Tracker from "@openreplay/tracker";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

let tracker;

if (process.env.NODE_ENV == "production") {
    tracker = new Tracker({
        projectKey: "JeP5TdQUSkoQtV8Gt0vg",
        ingestPoint: "https://openreplay.townweb.com/ingest",
        respectDoNotTrack: true,
        obscureTextEmails: false,
        obscureInputEmails: false,
        defaultInputMode: 0,
        network: {
            capturePayload: true, // Capture HTTP payload
        },
        onStart: ({ sessionToken }) => {
            // This is to link from OpenReplay -> Sentry
            Sentry.setTag("openReplaySessionToken", sessionToken);

            // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
            Sentry.setTag("openReplaySessionURL", tracker.getSessionURL());
        },
    });
    tracker.start();
}

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import { faPlus, faMinus, faStar, faArrowUp, faArrowDown, faChevronUp, faChevronDown, faCog, faFolderOpen, faFolder, faQuestion, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

library.add({
    faPlus,
    faMinus,
    faStar,
    faArrowUp,
    faArrowDown,
    faChevronUp,
    faChevronDown,
    faCog,
    faFolderOpen,
    faFolder,
    faQuestion,
    faEye,
    faEyeSlash,
});

Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    return JSON.parse(this.getItem(key));
};

let userName = "init";
let siteUrl = "init";

if (process.env.NODE_ENV == "production") {
    let user = sessionStorage.getObject("user") || localStorage.getObject("user");
    let site = localStorage.getObject("site");

    if (user) {
        userName = user.data.email || user.data.name;
    }

    if (site) {
        siteUrl = site.url;
    }

    tracker.setUserID(userName + "|" + siteUrl);
}

// Create a new store instance.
const store = createStore({
    state() {
        return {
            overlayShow: false,
            page: {},
            loginModalShow: false,
            subscribedToMinutes: false,
        };
    },
    mutations: {
        showOverlay(state) {
            state.overlayShow = true;
        },
        hideOverlay(state) {
            state.overlayShow = false;
        },
        setPage(state, { page }) {
            state.page = page;
        },
        showLoginModal(state) {
            state.loginModalShow = true;
        },
        hideLoginModal(state) {
            state.loginModalShow = false;
        },
        subscribedToMinutes(state) {
            state.subscribedToMinutes = true;
        },
    },
    getters: {
        overlayShow(state) {
            return state.overlayShow;
        },
        page(state) {
            return state.page;
        },
        loginModalShow(state) {
            return state.loginModalShow;
        },
    },
});

setupInterceptors(store);

// VueSweetalert2 options
const options = {
    confirmButtonColor: "#0FCB4F",
    cancelButtonColor: "#EC2641",
};

const app = createApp(App).use(router).use(moment).use(store).use(VueSweetalert2, options).use(PDFPlugin);

app.config.globalProperties.axios = axios;
app.config.globalProperties.moment = moment;
if (process.env.NODE_ENV == "production") {
    app.config.globalProperties.tracker = tracker;
}

app.component("font-awesome-icon", FontAwesomeIcon);

app.component("Datepicker", Datepicker);

app.directive("permission", permission);

// myUndefinedFunction(null);

// function myUndefinedFunction(data) {
//     console.log(data.item);
// }
if (process.env.NODE_ENV == "production") {
    Sentry.init({
        app,
        dsn: "https://1e3d0146c437aa3f8963a44219130f46@o518545.ingest.us.sentry.io/4508098985394176",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "dashboard.townweb.com/", "https://develop.tw-dashboard2.pages.dev", /^\//],
                // tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
// Sentry.init({
//     app,
//     dsn: "https://1e3d0146c437aa3f8963a44219130f46@o518545.ingest.us.sentry.io/4508098985394176",
//     integrations: [
//           Sentry.browserTracingIntegration({ router }),
//           Sentry.replayIntegration(),
//           Sentry.browserProfilingIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", "dashboard.townweb.com", 'https://develop.tw-dashboard2.pages.dev', /^\//],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     // Profiling
//     profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
// });

app.mount("#app");

/* part that counts number of open tabs so we can handle problem with multiple clients opened */
const APP_TAB_COUNT_KEY = 'dashboard-tab-count';

function updateTabCount(delta) {
    const currentCount = parseInt(localStorage.getItem(APP_TAB_COUNT_KEY) || '0', 10);
    const newCount = currentCount + delta;

    if (newCount <= 0) {
        localStorage.removeItem(APP_TAB_COUNT_KEY);
    } else {
        localStorage.setItem(APP_TAB_COUNT_KEY, newCount);
    }
    return newCount;
}

// When the tab is loaded
window.addEventListener('load', () => {
    updateTabCount(1);
});

// When the tab is closed
window.addEventListener('beforeunload', () => {
    updateTabCount(-1);
});
